.video-container {
    position: relative;
    display: flex;
    /* min-width: 27em; */
    overflow: hidden;
    aspect-ratio: 16/9;
    background-color: black;
}

.video-player {
    width: 100%;
    align-self: center;
}

.video-controls {
    width: 100%;
    bottom: 0;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), transparent);
    transform: translateY(100%);
    transition: 0.2s ease-in-out;
}

.video-controls-hover {
    transform: translateY(0);
    transition: 0.05s;
}

.timer-control {
    color: white;
    margin: 0 0.5em;
    font-size: 14px;
}

.audio-slider {
    width: 50px !important;
}

.playing-abstract-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: .7em;
    color: white;
    background-color: black;
    border-radius: 100%;
    transition: .3s ease-in;
    opacity: 0;
}

.abstract-icon-animation {
    animation: AbstractIconGrow .3s ease-in;
}

@keyframes AbstractIconGrow {
    50% {
        transform: translate(-50%, -50%) scale(2);
        opacity: .5;  
    }
    100% {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
    }
}